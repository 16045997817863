import { FC, useState, useEffect, useMemo, useCallback } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import { useBasicLoanServices } from "Hooks/API/useBasicLoanServices"
import { useInvestmentServices } from "Hooks/API/useInvestmentServices"
import {
  useGetDepositsAffectedByInterestRateChange,
  useGetDepositServices,
} from "Hooks/API/useDepositServices"
import { useGetLoanApplications } from "Hooks/API/useLoansApplications"
import { useGetUser } from "Hooks/API/useUser"
import PromoWrap from "Components/PromoWrap"
import { getInvestmentServiceSummary } from "ApiServices/investmentServices"
import {
  getLoansInfo,
  getLoanApplicationsInfo,
  getSavingsInfo,
  IData,
} from "Utils/getInfoUtils"
import { IInvestmentService } from "Interfaces/investmentServicesInterfaces"
import UserGreetings from "Components/UserGreetings"
import Loading from "UI/Loading"
import { Urls } from "Constants/urls"
import { ContractsToSign } from "Components/ContractsToSign/ContractsToSign"
import { useGetListLoanContracts } from "Hooks/API/useLoanContracts"
import { useGetInsuranceEligibilities } from "Hooks/API/useInurance"
import { InsurancePromotion } from "Components/InsurancePromotion/InsurancePromotion"
import {
  BROCC_LANDING_INVESTMENTS,
  BROCC_LANDING_LOAN,
  BROCC_LANDING_SAVINGS,
} from "Constants/externalLinks"
import * as S from "./Main.styles"

const Main: FC = () => {
  const intl = useIntl()
  const [investmentData, setInvestmentData] = useState<IData[]>([])
  const { data: user, isError, isLoading: isUserLoading } = useGetUser()
  const { data: loans, isLoading: isLoanLoading } = useBasicLoanServices()
  const insuranceEligibilitiesQuery = useGetInsuranceEligibilities()
  const { data: interestRateChange, isLoading: isLoadingInterestRateChange } =
    useGetDepositsAffectedByInterestRateChange()

  const getLoanApplications = useGetLoanApplications()
  const listLoanContracts = useGetListLoanContracts()
  const { data: investments, isLoading: isInvestLoading } =
    useInvestmentServices()
  const { data: savings, isLoading: isDepositLoading } = useGetDepositServices()
  const loanApplications =
    getLoanApplications.data?.data &&
    Object.values(getLoanApplications.data?.data)

  const isFetching = useMemo(
    () =>
      isUserLoading ||
      isLoanLoading ||
      getLoanApplications.isLoading ||
      isInvestLoading ||
      isDepositLoading ||
      insuranceEligibilitiesQuery.isLoading ||
      isLoadingInterestRateChange,
    [
      getLoanApplications.isLoading,
      isDepositLoading,
      isInvestLoading,
      isLoanLoading,
      isUserLoading,
      insuranceEligibilitiesQuery.isLoading,
      isLoadingInterestRateChange,
    ]
  )

  const getInvestmentsInfo = useCallback(
    async (invests: IInvestmentService[]) => {
      const newInvestmentsData = await Promise.all(
        invests
          .sort((a, b) => b.status.localeCompare(a.status))
          .map((item) => getInvestmentServiceSummary(item.uid))
      ).then((balances) =>
        balances.map((item, i) => ({
          id: invests[i].uid,
          accountNumber: invests[i].product_number,
          status: invests[i].status,
          balance:
            item.account_summary.balances.before_unrealized_value_changes,
          link:
            invests[i].status === "created"
              ? `${Urls.Investments}/${Urls.InvestmentsOnboarding.replace(
                  ":accountID",
                  invests[i].uid
                )}`
              : `${Urls.Investments}/${invests[i].uid}/${Urls.InvestmentsAccountOverview}`,
        }))
      )
      setInvestmentData(newInvestmentsData)
    },
    []
  )

  useEffect(() => {
    if (investments?.data && investments?.data.length > 0) {
      getInvestmentsInfo(investments.data)
    }
  }, [investments, getInvestmentsInfo])

  const loansData = useMemo(
    () =>
      loans?.data && loans?.data.length > 0 ? getLoansInfo(loans.data) : null,
    [loans?.data]
  )

  const loanApplicationsData = useMemo(
    () =>
      loanApplications && loanApplications.length > 0
        ? getLoanApplicationsInfo(loanApplications, intl)
        : [],
    [intl, loanApplications]
  )

  const savingsData = useMemo(
    () =>
      savings?.data && savings?.data.length > 0
        ? getSavingsInfo(savings.data, intl)
        : null,
    [intl, savings?.data]
  )

  const instmentmentsLoading = useMemo(
    () =>
      investments?.data &&
      investments?.data.length > 0 &&
      investmentData.length === 0,
    [investmentData.length, investments?.data]
  )

  const showInsurancePromotion = useMemo(() => {
    return Boolean(
      insuranceEligibilitiesQuery.data?.is_eligible &&
        insuranceEligibilitiesQuery.data.free_months > 0 &&
        loans?.data.some((loan) => loan.is_eligible_for_insurance)
    )
  }, [
    loans,
    insuranceEligibilitiesQuery.data?.is_eligible,
    insuranceEligibilitiesQuery.data?.free_months,
  ])

  if (isFetching || instmentmentsLoading) {
    return (
      <S.LoadingContainer>
        <Loading />
      </S.LoadingContainer>
    )
  }

  if (isError) {
    return (
      <div>
        <FormattedMessage id="app.login.error.generalError" />
      </div>
    )
  }

  return (
    <S.MainWrap>
      <UserGreetings
        interestRateChange={interestRateChange}
        username={user ? user.name : ""}
      />

      <S.OffersWrap>
        {listLoanContracts.data && listLoanContracts.data.data.length > 0 && (
          <ContractsToSign contracts={listLoanContracts.data.data} />
        )}

        {showInsurancePromotion && <InsurancePromotion />}

        {loanApplicationsData.length > 0 && (
          <S.LoansContainer>
            <h2>
              <FormattedMessage
                id="app.common.loanApplications"
                defaultMessage="Låneansökningar"
              />
            </h2>
            <PromoWrap
              type="loanApplications"
              accountTitleId="app.common.loanApplication"
              data={loanApplicationsData}
            />
          </S.LoansContainer>
        )}

        <PromoWrap
          type="loans"
          titleId="app.mypages.loans.header"
          secondTitleId="app.mypages.main.loansTitle"
          descriptionId="app.mypages.main.loansDescription"
          accountTitleId="app.mypages.loans.header"
          linkText="app.mypages.loans.applyForLoan.title"
          link={BROCC_LANDING_LOAN}
          data={loansData}
          descriptionList={[
            "app.mypages.loans.noLoans.listItem1",
            "app.mypages.loans.noLoans.listItem2",
            // "app.mypages.loans.noLoans.listItem3",
            "app.mypages.loans.noLoans.listItem4",
          ]}
        />

        {investmentData.length > 0 && (
          <PromoWrap
            type="investments"
            titleId="app.mypages.mainNav.investments.title"
            secondTitleId="app.mypages.main.investmentsTitle"
            descriptionId="app.mypages.main.investmentsDescription"
            accountTitleId="app.common.account.investmentAccount"
            linkText="app.mypages.mainNav.investments.requiresOnboarding"
            link={BROCC_LANDING_INVESTMENTS}
            data={investmentData}
          />
        )}

        <PromoWrap
          type="savings"
          titleId="app.mypages.mainNav.savings.title"
          secondTitleId="app.mypages.main.savingsTitle"
          descriptionId="app.mypages.main.savingsDescription"
          descriptionList={[
            "app.mypages.main.savingsDescriptionList.item1",
            "app.mypages.main.savingsDescriptionList.item2",
            "app.mypages.main.savingsDescriptionList.item3",
          ]}
          accountTitleId="app.common.account.savingsAccount"
          linkText="app.mypages.investments.requiresOnboarding.buttonCTA"
          link={BROCC_LANDING_SAVINGS}
          data={savingsData}
        />
      </S.OffersWrap>
    </S.MainWrap>
  )
}

export default Main
